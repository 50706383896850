/* eslint-disable no-shadow */
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import UserUpdateForm from 'components/updateUser'
import { RegisterUserForm } from 'screens/login/registerUserForm'
import { UsersTable } from 'components/Admin/UsersTable'
import axiosApi from 'utils/axiosApi'

import { AdminWrapper, Title } from './styles'
import { get } from 'lodash'

export const AdminScreen = () => {
  const { t } = useTranslation()

  const [user, setUser] = useState(null)
  const [show, setShowUserUpdateForm] = useState(false)
  const [newRequest, setNewRequest] = useState(0)

  const deleteUser = _id => {
    axiosApi.delete(`/users/${_id}`).then(() => {
      setNewRequest(newRequest + 1)
      setShowUserUpdateForm(false)
    })
  }

  const updateUser = async (id, userData, actions) => {
    try {
      if (userData.password && userData.password !== '') {
        await axiosApi.post('changePasswordByAdmin', {
          _id: userData._id,
          newPassword: userData.password,
        })
      }

      await axiosApi.patch(`/users/${id}`, { ...userData })
      setNewRequest(newRequest + 1)
      setShowUserUpdateForm(false)
    } catch (error) {
      const errorMsg = get(error, 'response.data.errors.msg.0.msg') || get(error, 'response.data.errors.msg')
      actions.setFieldError('general', errorMsg)
    }
  }

  const registerNewUser = (user, actions) => {
    axiosApi
      .post('/users', { ...user, role: 'user' })
      .then(res => {
        setNewRequest(newRequest + 1)
        actions.resetForm()
      })
      .catch(error => {
        const errorMsg = get(error, 'response.data.errors.msg.0.msg') || get(error, 'response.data.errors.msg')
        actions.setFieldError('general', errorMsg || 'You can not create this User')
      })
  }

  return (
    <AdminWrapper>
      <Title>{t('userManagmentText')}</Title>

      <RegisterUserForm onRegister={registerNewUser} />

      <UserUpdateForm user={user} onDelete={deleteUser} onUpdate={updateUser} onClose={() => setShowUserUpdateForm(false)} show={show} />

      <UsersTable
        apiUrl="users"
        headerBackground="#cde6fe"
        headerText={t('userManagment.tableHeader')}
        onUserClicked={user => {
          setShowUserUpdateForm(true)
          setUser(user)
        }}
        newRequest={newRequest}
      />
    </AdminWrapper>
  )
}
